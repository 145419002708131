:root {
    --color-primary: #2B2171;
    --color-secondary: #ffffff;
    --color-text-default: #000000;
    --color-text-muted: #868E96;

    --color-default: #2D343E;
    --color-white: #FFF;
    --color-light-grey: #D0D0D0;
    --color-dark-grey: #F9F8F8;
    --color-dark: #F9F8F8;

    --color-success: #28A745;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #000;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #1d164d;
    --color-secondary-dark: #2B2171;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #3A88C4;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "calibre-regular", sans-serif;
    --font-default-bold: "calibre-semibold", sans-serif;
    --font-default-medium: "calibre-semibold", sans-serif;

    --font-size-default: calc(18rem / 16);
    --font-size-default-mobile: calc(15rem / 16);
}
